import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

interface EmailAddressProps {
  value: string;
  showHelperText?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const EmailAddress = ({
  value,
  showHelperText = false,
  onChange,
}: EmailAddressProps) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <FormLabel htmlFor="email">{t('form.emailAddress')}</FormLabel>
      <Input
        id="email"
        type="email"
        value={value}
        onChange={onChange}
        required
      />
      {showHelperText && (
        <FormHelperText>{t('form.emailAddressHelp')}</FormHelperText>
      )}
    </FormControl>
  );
};
