import { MemberCard } from '../components/MemberCard/MemberCard';

export const MemberCardPage: React.FC = () => {
  return (
    <main className="member-card-page">
      <section>
        <MemberCard />
      </section>
    </main>
  );
};
