import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  IconProps,
  useColorModeValue,
} from '@chakra-ui/react';
import Markdown from 'markdown-to-jsx';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { globalDefaultContext } from '../../contexts/GlobalContext';
import { URL_HOW_IT_WORKS, URL_REGISTER_MEMBER } from '../../utils/constant';

export const Hero = () => {
  const { t } = useTranslation();

  return (
    <Flex py={globalDefaultContext.marginBetweenSections}>
      <Container maxW={'4xl'}>
        <Stack
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          pt={[0, 20]}
          pb={[10, 20]}
          direction={{ base: 'column', md: 'row' }}>
          <Stack
            flex={1}
            spacing={{ base: 5, md: 10 }}
            textAlign={['center', 'left']}
            zIndex={2}>
            <Heading
              lineHeight={1.1}
              fontWeight={800}
              fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}>
              <Text
                as={'span'}
                position={'relative'}
                style={{ position: 'relative', zIndex: 2 }}>
                {t('common.claimPart1')}
              </Text>
              <br />
              <Text as={'span'}>{t('common.claimPart2')}</Text>
            </Heading>
            <Text fontWeight={700}>
              <Markdown>{t('common.claimSubline')}</Markdown>
            </Text>
            <Stack
              spacing={{ base: 4, sm: 6 }}
              direction={{ base: 'column', sm: 'row' }}>
              <NavLink to={URL_REGISTER_MEMBER}>
                <Button w="100%">{t('common.registerMember')}</Button>
              </NavLink>
              <NavLink to={URL_HOW_IT_WORKS}>
                <Button variant="outline">{t('common.learnMore')}</Button>
              </NavLink>
            </Stack>
          </Stack>
          <Flex
            flex={1}
            justify={'center'}
            align={'center'}
            position={'relative'}
            w={'full'}>
            <Blob
              w={['100%', '120%']}
              h={['100%', '120%']}
              position={'absolute'}
              top={['0', '-10%']}
              left={['0', '-10%']}
              zIndex={1}
              color={useColorModeValue('yellow.200', 'red.800')}
            />
            <Box
              position={'relative'}
              height={'300px'}
              rounded={'2xl'}
              boxShadow={'2xl'}
              width={'full'}
              overflow={'hidden'}
              transform={'rotate(-3deg)'}
              zIndex={2}>
              <Image
                alt={'Foto von Christiann Koepke auf Unsplash'}
                fit={'cover'}
                align={'center'}
                w={'100%'}
                h={'100%'}
                src={
                  process.env.PUBLIC_URL +
                  '/images/hero/premium_photo-1661767330128-d4b77d741ff9.avif'
                }
              />
              <Text
                mt={-6}
                ml={2}
                color={'whiteAlpha.700'}
                fontSize={'sm'}
                textAlign={'center'}>
                Photo:{' '}
                <a href="https://unsplash.com/de/fotos/0hC4VNEDUz4">
                  Christiann Koepke
                </a>{' '}
                , Unsplash
              </Text>
            </Box>
          </Flex>
        </Stack>
      </Container>
    </Flex>
  );
};

export const Blob = (props: IconProps) => {
  return (
    <Icon
      width={'100%'}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};
