import { useEffect, useState } from 'react';
import { RegisterCompanyForm } from '../components/RegisterCompanyForm/RegisterCompanyForm';
import { readUser } from '../services/directus';
import { DirectusUsers } from '../utils/types';

export const RegisterCompany = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const aid = queryParams.get('aid');
  const [agent, setAgent] = useState<DirectusUsers>();

  useEffect(() => {
    if (aid) {
      readUser(aid)
        .then((response) => {
          if (response) {
            const companyAgent: DirectusUsers = {
              id: aid,
              first_name: response.first_name || undefined,
              last_name: response.last_name || undefined,
              email: response.email || undefined,
            };

            setAgent(companyAgent);
          }
        })
        .catch(() => {
          setAgent(undefined);
        });
    }
  }, []);

  return (
    <main className="register-company">
      <section>
        <RegisterCompanyForm agent={agent} />
      </section>
    </main>
  );
};
