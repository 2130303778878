import { createContext, ReactNode, useEffect, useState } from 'react';
import { LOCALSTORAGE_USER_DATA } from '../utils/constant';
import {
  initialAuthContext,
  initialMembership,
  initialUser,
} from '../utils/initial-values';
import { DirectusUsers, Memberships, UserRoles } from '../utils/types';
import {
  loginUser,
  logoutUser,
  readBonavendoUser,
  readLoggedInUser,
  readMemberships,
  refreshToken,
} from './directus';
import { useToast } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Users } from '../utils/types';

const {
  REACT_APP_MEMBER_USER_ROLE_ID,
  REACT_APP_MEMBER_MANAGER_ROLE_ID,
  REACT_APP_MEMBER_AGENT_ROLE_ID,
} = process.env;

export const AuthContext = createContext(initialAuthContext);

export interface UserData extends DirectusUsers {
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  role: UserRoles;
  status: string;
  date_created: string;
}

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState<UserRoles>(UserRoles.USER);
  const [errorStatus, setErrorStatus] = useState<number>();
  const [userData, setUserData] = useState<UserData>(initialUser as UserData);
  const toast = useToast();
  const { t } = useTranslation();

  const handleUserRole = (role: UserRoles) => {
    switch (role) {
      case REACT_APP_MEMBER_USER_ROLE_ID:
        setUserRole(UserRoles.MEMBER);
        break;
      case REACT_APP_MEMBER_MANAGER_ROLE_ID:
        setUserRole(UserRoles.MANAGER);
        break;
      case REACT_APP_MEMBER_AGENT_ROLE_ID:
        setUserRole(UserRoles.AGENT);
        break;
      default:
        setUserRole(UserRoles.USER);
    }
  };

  const getUserData = async () => {
    try {
      const responseUser = await readLoggedInUser();
      if (!responseUser) {
        cleanup();
        return;
      }

      const bonavendoUserResponse = await readBonavendoUser(
        `${responseUser.id}`
      );

      const bonavendoUser = bonavendoUserResponse.data?.[0] as Users;

      let membership = initialMembership;
      if (bonavendoUser.id) {
        const membershipsResponse = await readMemberships(bonavendoUser.id);
        membership = membershipsResponse.data?.[0] as Memberships;
      }

      const updatedUserData: UserData = {
        ...userData,
        email: responseUser.email ?? '',
        first_name: responseUser.first_name ?? '',
        id: String(bonavendoUser.id) ?? '',
        last_name: responseUser.last_name ?? '',
        role: (responseUser.role as UserRoles) ?? UserRoles.USER,
        status: membership.status ?? '',
        date_created: membership.date_created ?? '',
      };
      handleUserRole(updatedUserData.role);
      localStorage.setItem(
        LOCALSTORAGE_USER_DATA,
        JSON.stringify(updatedUserData)
      );
      setUserData(updatedUserData);
      setIsAuthenticated(true);
      setIsLoading(false);
    } catch (error) {
      // @ts-ignore
      if (error?.response?.status) {
        // @ts-ignore
        setErrorStatus(error.response.status);
      } else {
        setErrorStatus(undefined);
      }
      cleanup();
    }
  };

  const closeErrorStatus = () => {
    setErrorStatus(undefined);
  };

  const login = ({ email, password }: { email: string; password: string }) => {
    setIsLoading(true);
    loginUser(email, password)
      .then((responseLogin) => {
        if (responseLogin.access_token) {
          getUserData();
        }
      })
      .catch((error) => {
        if (error?.response?.status) {
          setErrorStatus(error.response.status);
        } else {
          setErrorStatus(undefined);
        }
        cleanup();
      });
  };

  const cleanup = () => {
    setUserData(initialUser as UserData);
    setIsAuthenticated(false);
    setIsLoading(false);
    setUserRole(UserRoles.USER);
    setErrorStatus(undefined);
  };

  const logout = () => {
    cleanup();
    logoutUser();

    toast({
      description: t('logout.toast.success.description'),
      duration: 5000,
      isClosable: true,
      status: 'success',
      title: t('logout.toast.success.title'),
    });
  };

  const getToken = async () => {
    try {
      const responseToken = await refreshToken();
      setIsAuthenticated(true);
      return responseToken;
    } catch (error) {
      setUserData(initialUser as UserData);
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        userData,
        isAuthenticated,
        userRole,
        errorStatus,
        closeErrorStatus,
        login,
        logout,
        getToken,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
