import { Route, Routes } from 'react-router-dom';

import { DefaultLayout } from './layouts/Default';
import { Company } from './pages/Company';
import { Home } from './pages/Home';
import { NotFound } from './pages/NotFound';
import { Page } from './pages/Page';
import { RegisterMember } from './pages/RegisterMember';
import { RegisterCompany } from './pages/RegisterCompany';
import { LoginPage } from './pages/LoginPage';
import { MemberArea } from './pages/MemberArea';
import { Dashboard } from './pages/Dashboard';
import { MemberCardPage } from './pages/MemberCardPage';
import { Search } from './pages/Search';
import { ConfirmationPage } from './pages/ConfirmationPage';

import {
  URL_DASHBOARD,
  URL_LOGIN,
  URL_MEMBER,
  URL_MEMBER_CARD,
  URL_NOT_FOUND,
  URL_PAGE,
  URL_REGISTER_COMPANY,
  URL_REGISTER_MEMBER,
  URL_ROOT,
  URL_SEARCH,
  URL_CONFIRMATION,
} from './utils/constant';

export const App = () => {
  return (
    <Routes>
      <Route path={URL_ROOT} element={<DefaultLayout />}>
        <Route index element={<Home />} />
        <Route path={URL_SEARCH} element={<Search />} />
        <Route path={URL_ROOT + ':slug'} element={<Company />} />
        <Route path={URL_PAGE + '/:slug'} element={<Page />} />
        <Route path={URL_REGISTER_MEMBER} element={<RegisterMember />} />
        <Route path={URL_REGISTER_COMPANY} element={<RegisterCompany />} />
        <Route path={URL_MEMBER} element={<MemberArea />} />
      </Route>
      <Route path={URL_LOGIN} element={<LoginPage />} />
      <Route path={URL_CONFIRMATION} element={<ConfirmationPage />} />
      <Route path={URL_DASHBOARD} element={<Dashboard />} />
      <Route path={URL_MEMBER_CARD} element={<MemberCardPage />} />
      <Route path={URL_NOT_FOUND} element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
