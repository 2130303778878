import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { Input } from './Input';

export const NumberInput: ComponentStyleConfig = {
  parts: ['field'],
  variants: {
    // @ts-ignore
    outline: (props) => Input.variants.outline(props) ?? {},
  },
  defaultProps: {
    size: 'lg',
    variant: 'outline',
  },
};
