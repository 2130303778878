import { ChangeEvent, FormEvent } from 'react';
import { SearchIcon } from '@chakra-ui/icons';
import {
  FormControl,
  InputGroup,
  Input,
  InputRightElement,
  FormLabel,
  VisuallyHidden,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface SearchInputProps {
  value: string;
  onInput: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export const SearchInput = ({
  value = '',
  onInput,
  onSubmit,
}: SearchInputProps) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={(event) => onSubmit(event)}>
      <FormControl>
        <VisuallyHidden>
          <FormLabel htmlFor="q">{t('search.label')}</FormLabel>
        </VisuallyHidden>
        <InputGroup>
          <Input
            type="search"
            onChange={(event) => onInput(event)}
            name="q"
            value={value}
            placeholder={t('search.label') || ''}
          />
          <InputRightElement pointerEvents="none" children={<SearchIcon />} />
        </InputGroup>
      </FormControl>
    </form>
  );
};
