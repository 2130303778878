import { createContext } from 'react';

interface GlobalContextProps {
  marginBetweenSections: number[];
}

export const globalDefaultContext: GlobalContextProps = {
  marginBetweenSections: [10, 20],
};

const GlobalContext = createContext<GlobalContextProps>(globalDefaultContext);

export default GlobalContext;
