import { extendTheme } from '@chakra-ui/react';
import { mode, StyleFunctionProps } from '@chakra-ui/theme-tools';
import { Button } from './components/Button';
import { Input } from './components/Input';
import { NumberInput } from './components/NumberInput';
import { Select } from './components/Select';
import { Textarea } from './components/Textarea';
import '@fontsource/karla';

export const bonavendoTheme = extendTheme({
  config: {
    initialColorMode: 'system',
    useSystemColorMode: true,
  },

  fonts: {
    heading: 'Vollkorn, serif',
    body: 'Karla, sans-serif',
  },

  colors: {
    red: {
      '50': '#FFE6E6',
      '100': '#FEB9B9',
      '200': '#FD8C8C',
      '300': '#FC5F5F',
      '400': '#FB3232',
      '500': '#FA0505',
      '600': '#C80404',
      '700': '#960303',
      '800': '#640202',
      '900': '#320101',
    },
    gray: {
      '50': '#F2F2F2',
      '100': '#DBDBDB',
      '200': '#C4C4C4',
      '300': '#ADADAD',
      '400': '#969696',
      '500': '#808080',
      '600': '#666666',
      '700': '#4D4D4D',
      '800': '#333333',
      '900': '#1A1A1A',
    },
    yellow: {
      '50': '#F8F5ED',
      '100': '#EAE3CD',
      '200': '#DDD1AC',
      '300': '#CFBF8C',
      '400': '#C2AD6B',
      '500': '#B49A4B',
      '600': '#907C3C',
      '700': '#6C5D2D',
      '800': '#483E1E',
      '900': '#241F0F',
    },
    brand: {
      red: '#D90404',
      lightRed: '#D90404',
      darkRed: '#730202',
      dark: '#400106',
      light: '#EAE3CD', //'#DFD4B2',
    },
    primaryFontColor: {
      lightMode: 'brand.dark',
      darkMode: 'brand.light',
    },
    secondaryFontColor: {
      lightMode: 'brand.dark',
      darkMode: 'brand.light',
    },
  },

  shadows: {
    outline: '#77FA48',
  },

  styles: {
    global: (props: StyleFunctionProps) => ({
      html: { minHeight: '100%', overflowY: 'scroll' },
      body: {
        backgroundColor: mode('brand.light', 'brand.dark')(props),
        backgroundImage: 'url("/images/backgrounds/mirrored-squares.png")',
        backgroundRepeat: 'repeat',
        color: mode('brand.dark', 'brand.light')(props),
        minHeight: '100%',
      },
      'ul, ol': {
        paddingStart: 5,
      },
      a: {
        color: mode('blue.500', 'blue.400')(props),
      },
    }),
  },

  components: {
    Button,
    Input,
    NumberInput,
    Select,
    Textarea,
  },
});
