import { Flex, Link, Spacer } from '@chakra-ui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../contexts/GlobalContext';

export const Imprint = () => {
  const { t } = useTranslation();
  const { marginBetweenSections } = useContext(GlobalContext);

  return (
    <Flex fontSize="xs" my={marginBetweenSections}>
      <Link noOfLines={1} href="/page/imprint">
        {t('common.imprint')}
      </Link>
      <Spacer />
      <Link noOfLines={1} href="/page/privacy">
        {t('common.dataProtection')}
      </Link>
      <Spacer />
      <Link noOfLines={1} href="/page/terms">
        {t('common.termsOfUse')}
      </Link>
    </Flex>
  );
};
