import { SVGProps } from 'react';

export interface LogoProps {
  color?: string;
  margin?: string;
  width?: string;
}

export const Logo = (
  { color = '#ffffff', margin, width = '200px' }: LogoProps,
  props: SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={1150}
      height={154}
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'auto',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        maxWidth: width,
        height: 'auto',
        margin: margin,
      }}
      viewBox="0 0 1150 154"
      {...props}>
      <g id="bonavendo_svg__Layer_x0020_1">
        <g id="bonavendo_svg___1806187967040">
          <path
            style={{ fill: color, fillRule: 'nonzero' }}
            d="M294.97 77.22c0-17.88 11.84-30.88 30.42-30.88 18.34 0 30.18 13 30.18 30.88v53.96c0 10.68 8.71 19.4 19.4 19.4h.1V77.91c0-30.18-21.13-49.91-49.68-49.91-28.56 0-49.92 19.73-49.92 49.91v72.67h.1c10.69 0 19.4-8.72 19.4-19.4V77.22zm219.06 13.7c0-35.29-26-62.92-59.9-62.92C420.93 28 394 54.93 394 90.92c0 35.75 26.7 62.68 59.67 62.68 12.82 0 24.57-4.92 33.56-12.94v-24.37c-7.35 11.49-19.52 18.97-33.1 18.97-22.29 0-40.4-19.27-40.4-44.34 0-25.08 18.11-44.81 40.4-44.81 21.82 0 40.4 19.96 40.4 44.81v40.26c0 10.69 8.71 19.4 19.4 19.4h.1V90.92zm51.22 59.66h21.12l43.42-119.56h-20.9l-32.96 93.33h-.24l-32.96-93.33h-20.9z"
          />
          <path
            style={{ fill: color }}
            d="M58.06 135.2c-20.91-1.21-38.33-20.7-38.33-44.75 0-24.61 18.34-44.34 40.4-44.34 22.05 0 40.16 19.5 40.16 44.34 0 1.7-.08 3.37-.24 5.01l18.1-21.08C111.57 46.99 88.36 28 60.36 28c-16.48 0-31.57 7.89-41.32 20.2V18.87C19.04 8.48 10.56 0 .17 0H0v90.45c0 34.8 25.17 61.79 58.06 62.88.69.03 1.38.04 2.07.04 23.03 0 35.77-10.35 49.96-26.71l45.01-51.91c11.04-12.73 21.86-27.49 40.43-27.93.3-.01.59-.02.88-.02 22.29 0 40.39 19.97 40.39 44.35 0 23.91-18.1 43.41-40.39 43.41-22.06 0-40.17-19.5-40.17-43.41 0-1.78.1-3.53.29-5.26l-18.28 21.29c6.69 26.66 29.73 46.42 58.16 46.42 34.13 0 60.13-28.09 60.13-62.45 0-34.83-26-63.15-60.13-63.15-.29 0-.58 0-.88.01-18.94.27-33.28 8.43-45.33 22.47l-46.82 54.53c-7.4 8.88-22.32 30.25-43.25 30.25-.69 0-1.38-.02-2.07-.06z"
          />
          <path
            style={{ fill: color, fillRule: 'nonzero' }}
            d="M735.33 113.43c-7.66 13.93-19.5 21.36-36.45 21.36-13.69 0-26.93-6.73-34.59-18.8L756 77.22C749.03 46.11 723.72 28 697.72 28c-33.2 0-60.13 28.32-60.13 62.92 0 35.05 26.93 62.45 61.29 62.45 27.63 0 47.6-14.4 56.88-39.94h-20.43zm-78-22.75c0-27.39 21.35-44.11 40.39-44.11 13.47 0 26.7 8.13 34.13 21.13l-73.83 31.11c-.46-2.55-.69-5.11-.69-8.13zm137.1-13.46c0-17.88 11.84-30.88 30.41-30.88 18.34 0 30.18 13 30.18 30.88v53.96c0 10.68 8.72 19.4 19.4 19.4h.11V77.91c0-30.18-21.13-49.91-49.69-49.91-28.55 0-49.91 19.73-49.91 49.91v72.67h.1c10.68 0 19.4-8.72 19.4-19.4V77.22z"
          />
          <path
            style={{ fill: color }}
            d="m1029.95 96.22 23.41-27.18c10.47-11.24 18.59-22.47 36.51-22.47 22.29 0 40.39 19.97 40.39 44.35 0 23.91-18.1 43.41-40.39 43.41-22.06 0-40.17-19.5-40.17-43.41 0-2.02.13-4 .37-5.94l-18.49 21.46c6.52 26.91 29.67 46.93 58.29 46.93 34.13 0 60.13-28.09 60.13-62.45 0-34.83-26-63.15-60.13-63.15-19.32 0-33.99 8.22-46.26 22.54l-30.12 35.13-22.85 26.65c-6.18 7.16-18.89 23.17-37.05 23.17-22.29 0-40.4-19.5-40.4-44.81 0-24.84 18.11-44.34 40.4-44.34 21.95.14 40.16 19.82 40.16 44.34 0 1.9-.11 3.77-.32 5.61l20.06-23.39V0h-.64c-10.39 0-18.87 8.48-18.87 18.87v28.75a52.968 52.968 0 0 0-7.3-7.37v-.02C977.54 32.59 965.87 28 953.12 28c-32.96 0-59.66 26.7-59.66 62.45 0 35.99 26.93 62.92 60.13 62.92 22 0 35.19-9.35 48.8-25.16l27.56-31.99z"
          />
        </g>
      </g>
    </svg>
  );
};

export const LogoIcon = (
  { color = '#ffffff', width = '48px' }: LogoProps,
  props: SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={525}
      height={525}
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'auto',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        maxWidth: width,
        height: 'auto',
      }}
      viewBox="0 0 230.2 230.2"
      {...props}>
      <rect
        width={230.9}
        height={230.9}
        rx={39.12}
        ry={39.12}
        style={{
          fill: '',
          fillOpacity: 0,
        }}
      />
      <path
        d="M71.27 151.55c-13.05-.75-23.92-12.91-23.92-27.93 0-15.36 11.45-27.68 25.22-27.68 13.76 0 25.07 12.17 25.07 27.68 0 1.06-.05 2.1-.15 3.13l11.3-13.16c-4.12-17.1-18.6-28.95-36.08-28.95-10.29 0-19.71 4.92-25.8 12.61V78.94c0-6.49-5.29-11.78-11.77-11.78h-.11v56.46c0 21.72 15.71 38.57 36.24 39.25.43.02.86.03 1.3.03 14.37 0 22.32-6.46 31.18-16.67l28.1-32.41c6.89-7.94 13.65-17.16 25.24-17.44h.54c13.92 0 25.22 12.46 25.22 27.68 0 14.92-11.3 27.1-25.22 27.1-13.76 0-25.07-12.18-25.07-27.1 0-1.11.06-2.21.18-3.29l-11.41 13.3c4.17 16.63 18.56 28.97 36.3 28.97 21.31 0 37.54-17.54 37.54-38.98 0-21.74-16.23-39.42-37.54-39.42h-.54c-11.83.17-20.78 5.27-28.3 14.03l-29.22 34.04c-4.63 5.54-13.94 18.88-27 18.88-.44 0-.87-.01-1.3-.04z"
        style={{
          fill: color,
        }}
      />
    </svg>
  );
};
