import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { EmailAddress } from '../EmailAddress/EmailAddress';
import { Password } from '../Password/Password';
import { DirectusUsers } from '../../utils/types';

export interface UserProps {
  user: DirectusUsers;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const User = ({ user, onChange }: UserProps) => {
  const { t } = useTranslation();

  return (
    <>
      <FormControl>
        <FormLabel htmlFor="first_name">{t('form.firstName')}</FormLabel>
        <Input
          id="first_name"
          type="text"
          value={user.first_name}
          onChange={onChange}
          required
        />
      </FormControl>

      <FormControl>
        <FormLabel htmlFor="last_name">{t('form.lastName')}</FormLabel>
        <Input
          id="last_name"
          type="text"
          value={user.last_name}
          onChange={onChange}
          required
        />
      </FormControl>

      <EmailAddress
        value={`${user.email}`}
        showHelperText={true}
        onChange={onChange}
      />

      <Password
        value={`${user.password}`}
        applyRegex={true}
        showHelperText={true}
        onChange={onChange}
      />
    </>
  );
};
