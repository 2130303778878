import { UserData } from '../services/auth';
import { userRole } from '../services/directus';
import {
  Addresses,
  Companies,
  DirectusUsers,
  LoginProps,
  Users,
  AuthProps,
  UserRoles,
  Memberships,
  MembershipStatus,
  GeoIp,
} from './types';

export const initialUser: DirectusUsers = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
};

export const initialAddress: Addresses = {
  id: 0,
  country: undefined,
  state: undefined,
  postal_code: '',
  city_location: '',
  address_area: '',
};

export const initialRegisterUser: Users = {
  user: { ...initialUser, role: userRole.user },
  address: { ...initialAddress },
};

export const initialLogin: LoginProps = {
  email: '',
  password: '',
};

export const initialCompany: Companies = {
  manager: { ...initialUser },
  location: { ...initialAddress },
  name: '',
  number_of_employees: 0,
};

export const initialGeoIp: GeoIp = {
  city: {
    geoname_id: 0,
    names: {
      de: '',
      en: '',
      es: '',
      fr: '',
      it: '',
    },
  },
  continent: {
    code: '',
    geoname_id: 0,
    names: {
      de: '',
      en: '',
      es: '',
      fr: '',
      it: '',
    },
  },
  country: {
    geoname_id: 0,
    is_in_european_union: true,
    iso_code: '',
    names: {
      de: '',
      en: '',
      es: '',
      fr: '',
      it: '',
    },
  },
  location: {
    accuracy_radius: 0,
    latitude: 0,
    longitude: 0,
    time_zone: '',
  },
  postal: {
    code: '',
  },
  registered_country: {
    geoname_id: 0,
    is_in_european_union: true,
    iso_code: '',
    names: {
      de: '',
      en: '',
      es: '',
      fr: '',
      it: '',
    },
  },
  subdivisions: [
    {
      geoname_id: 0,
      iso_code: '',
      names: {
        de: '',
        en: '',
        es: '',
        fr: '',
        it: '',
      },
    },
  ],
};

export const initialAuthContext: AuthProps = {
  isLoading: false,
  userData: initialUser as UserData,
  isAuthenticated: false,
  userRole: UserRoles.USER,
  errorStatus: undefined,
  closeErrorStatus: () => '',
  login: () => '',
  logout: () => '',
  getToken: () => '',
};

export const initialMembership: Memberships = {
  date_created: '',
  date_updated: '',
  id: 0,
  invoices: [],
  status: MembershipStatus.pending,
  user: 0,
};
