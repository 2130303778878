import { Box, Container, Heading, SimpleGrid } from '@chakra-ui/react';
import { DirectusUsers } from '../../utils/types';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import GlobalContext from '../../contexts/GlobalContext';
import { Tile } from '../Tile/Tile';
import {
  // MdOutlineAccountCircle,
  MdOutlineCardMembership,
  MdOutlineLogout,
  // MdOutlineSettings,
} from 'react-icons/md';
import { AuthContext } from '../../services/auth';
import { useNavigate } from 'react-router-dom';
import { URL_LOGIN, URL_MEMBER_CARD, URL_ROOT } from '../../utils/constant';

interface MemberDashboardProps {
  userData: DirectusUsers;
}

export const MemberDashboard = ({ userData }: MemberDashboardProps) => {
  const { logout, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { marginBetweenSections } = useContext(GlobalContext);

  if (!isAuthenticated) {
    navigate(URL_ROOT);
  }

  return (
    <Box w="full">
      <Container>
        <Box py={marginBetweenSections}>
          <Heading>{t('common.memberArea')}</Heading>
          <Box mt={3} mb={10}>
            <p>
              {t('common.greeting')} {userData.first_name}
            </p>
          </Box>
          <SimpleGrid columns={[2, 3]} spacing={5}>
            <Tile
              icon={MdOutlineCardMembership}
              title={t('common.memberCard')}
              onClick={() => navigate(URL_MEMBER_CARD)}
            />
            {/* <Tile icon={MdOutlineAccountCircle} title={t('common.profile')} /> */}
            {/* <Tile icon={MdOutlineSettings} title={t('common.settings')} /> */}
            <Tile
              icon={MdOutlineLogout}
              title={t('common.logout')}
              onClick={logout}
            />
          </SimpleGrid>
        </Box>
      </Container>
    </Box>
  );
};
