import { Confirmation } from '../components/Confirmation/Confirmation';

export const ConfirmationPage = () => {
  return (
    <main className="confirmation">
      <section>
        <Confirmation />
      </section>
    </main>
  );
};
