import { Container, Spinner, Text } from '@chakra-ui/react';

export const Loading = ({ text }: { text: string }) => {
  return (
    <Container centerContent py="20">
      <Text mb="5">{text}</Text>
      <Spinner size={'lg'} />
    </Container>
  );
};
