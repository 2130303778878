import type { ComponentStyleConfig } from '@chakra-ui/theme';

export const Button: ComponentStyleConfig = {
  variants: {
    solid: (props) => ({
      bg: 'brand.red',
      color: 'yellow.50',
      _hover: {
        bg: props.colorMode === 'dark' ? 'yellow.50' : 'brand.dark',
        color: props.colorMode === 'dark' ? 'brand.dark' : 'yellow.50',
      },
    }),
    outline: (props) => ({
      borderColor: props.colorMode === 'dark' ? 'brand.light' : 'brand.dark',
      color: props.colorMode === 'dark' ? 'brand.light' : 'brand.dark',
      _hover: {
        bg: props.colorMode === 'dark' ? 'brand.light' : 'brand.dark',
        borderColor: props.colorMode === 'dark' ? 'brand.light' : 'brand.dark',
        color: props.colorMode === 'dark' ? 'brand.dark' : 'brand.light',
      },
    }),
    ghost: (props) => ({
      color: props.colorMode === 'dark' ? 'brand.light' : 'brand.dark',
      _hover: {
        bg: props.colorMode === 'dark' ? 'brand.light' : 'brand.dark',
        color: props.colorMode === 'dark' ? 'brand.dark' : 'brand.light',
      },
    }),
  },
  defaultProps: {
    size: 'lg',
  },
};
