import { Box, Container, Flex, Stack } from '@chakra-ui/react';
import { ChangeEvent, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { globalDefaultContext } from '../../contexts/GlobalContext';
import { Companies } from '../../utils/types';
import { Loading } from '../Loading/Loading';
import { SearchCard } from '../SearchCard/SearchCard';
import { SearchInput } from '../SearchInput/SearchInput';

interface SearchListProps {
  query: string;
  results: Companies[];
  isLoading: boolean;
  onInput: (event: ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export const SearchList = ({
  query,
  results,
  isLoading,
  onInput,
  onSubmit,
}: SearchListProps) => {
  const { t } = useTranslation();

  return (
    <main className="search-list">
      <section>
        <Flex py={globalDefaultContext.marginBetweenSections}>
          <Container maxW={'4xl'}>
            <Box mb={20}>
              <SearchInput
                value={query}
                onInput={(event) => onInput(event)}
                onSubmit={(event) => onSubmit(event)}
              />
            </Box>

            {isLoading && <Loading text={t('common.loadingPage')} />}

            {!isLoading && (
              <Stack direction={'column'} spacing={'5'}>
                {results.length === 0 && (
                  <Box textAlign={'center'} py={'20'} mb={'20'}>
                    {t('search.noMatches')}
                  </Box>
                )}
                {results.map((match, index) => {
                  return <SearchCard key={index} company={match} />;
                })}
              </Stack>
            )}
          </Container>
        </Flex>
      </section>
    </main>
  );
};
