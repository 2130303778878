import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  SimpleGrid,
  Stack,
} from '@chakra-ui/react';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../contexts/GlobalContext';
import { GeoIpContext } from '../../services/geoip';
import { useCountries } from '../../hooks/countries';
import { useCurrencies } from '../../hooks/currencies';
import {
  createAddress,
  createCompany,
  createCompanyUser,
  createInvoice,
  createInvoiceMembership,
  createMembership,
  createMembershipInvoice,
  createUser,
  registerUser,
} from '../../services/directus';
import { scrollToView } from '../../utils/browser';
import { focusField, initForm } from '../../utils/form';
import { initialCompany } from '../../utils/initial-values';
import { LanguageCodes } from '../../utils/language';
import {
  Addresses,
  Companies,
  DirectusUsers,
  Invoices,
  Memberships,
  Users,
} from '../../utils/types';
import { Address } from '../Address/Address';
import { AlertBox } from '../AlertBox/AlertBox';
import { RegisterAgentInfo } from '../RegisterAgentInfo/RegisterAgentInfo';
import { SwitchTermsAndConditions } from '../SwitchTermsAndConditions/SwitchTermsAndConditions';
import { User } from '../User/User';

const {
  REACT_APP_COST_OF_MEMBERSHIP_PER_MONTH,
  REACT_APP_MEMBER_MANAGER_ROLE_ID,
} = process.env;

const LOCALSTORAGE_COMPANY_DATA = 'bonavendoCompanyData';
const LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS =
  'bonavendoCompanyTermsAndConditionsChecked';
const FOCUS_FIRST_FIELD = 'firstName';

export const RegisterCompanyForm = ({ agent }: { agent?: DirectusUsers }) => {
  const [hasNoUniqueEmail, setHasNoUniqueEmail] = useState(false);
  const { i18n, t } = useTranslation();
  const { marginBetweenSections } = useContext(GlobalContext);
  const { country } = useContext(GeoIpContext);
  const countries = useCountries(i18n.language as LanguageCodes);
  const currencies = useCurrencies(i18n.language as LanguageCodes);

  const storedCompanyData: Companies = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_COMPANY_DATA) ||
      JSON.stringify(initialCompany)
  );
  const storedTermsAndConditionsChecked = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS) || 'false'
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(
    storedTermsAndConditionsChecked === true || false
  );
  const [successfullyAdded, setSuccessfullyAdded] = useState(false);
  const [companyData, setCompanyData] = useState<Companies>({
    manager: storedCompanyData.manager,
    location: storedCompanyData.location,
    name: storedCompanyData.name,
    cover_image: storedCompanyData.cover_image,
    number_of_employees: storedCompanyData.number_of_employees
      ? storedCompanyData.number_of_employees
      : 1,
  });
  const [paymentAmount, setPaymentAmount] = useState('12');

  const currentCountry = countries.find(
    (c) => c.id === Number((companyData?.location as Addresses)?.country)
  );
  const currentCurrency = currencies?.find(
    (c) => c.id === currentCountry?.currency
  );

  const cleanupCompanyData = () => {
    setCompanyData({ ...initialCompany });
    setSuccessfullyAdded(true);
    setIsLoading(false);
    setIsChecked(false);
    localStorage.removeItem(LOCALSTORAGE_COMPANY_DATA);
    localStorage.removeItem(LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS);
  };

  const handleChecked = () => {
    setIsChecked(!isChecked);
    localStorage.setItem(
      LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS,
      JSON.stringify(!isChecked)
    );
  };

  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    type: 'user' | 'company' | 'address'
  ) => {
    const manager = companyData.manager as DirectusUsers;
    const userObject = {
      ...manager,
      ...(type === 'user'
        ? { [event.currentTarget.id]: event.currentTarget.value }
        : {}),
      role: REACT_APP_MEMBER_MANAGER_ROLE_ID,
    };

    const company = companyData;
    const companyObject = {
      ...company,
      ...(type === 'company'
        ? { [event.currentTarget.id]: event.currentTarget.value }
        : {}),
    };

    const address = companyData.location as Addresses;
    const addressObject = {
      ...address,
      ...(type === 'address'
        ? { [event.currentTarget.id]: event.currentTarget.value }
        : {}),
    };

    const changedCompanyData = {
      ...companyData,
      ...companyObject,
      manager: {
        ...userObject,
      },
      location: {
        ...addressObject,
      },
    };

    setCompanyData(changedCompanyData);
    localStorage.setItem(
      LOCALSTORAGE_COMPANY_DATA,
      JSON.stringify(changedCompanyData)
    );
  };

  const handleNumberOfEmployees = (
    valueAsString: string,
    valueAsNumber: number
  ) => {
    handlePaymentAmount(valueAsNumber);

    const changedCompanyData = {
      ...companyData,
      number_of_employees: valueAsNumber,
    };
    setCompanyData(changedCompanyData);
    localStorage.setItem(
      LOCALSTORAGE_COMPANY_DATA,
      JSON.stringify(changedCompanyData)
    );
  };

  const handlePaymentAmount = (number_of_employees: number) => {
    const costOfMembershipPerMonth = REACT_APP_COST_OF_MEMBERSHIP_PER_MONTH;
    const paymentAmount =
      costOfMembershipPerMonth && number_of_employees >= 0
        ? `${12 * parseInt(costOfMembershipPerMonth) * number_of_employees}`
        : '0';
    setPaymentAmount(paymentAmount);
  };

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const manager = companyData.manager as DirectusUsers;
      const responseUser = (await registerUser({
        ...manager,
        language: i18n.language,
      }).catch((err) => {
        for (const error of err.errors) {
          if (error.message.includes('email')) {
            setHasNoUniqueEmail(true);
          }
        }
      })) as DirectusUsers;

      const location = companyData.location as Addresses;
      const addressObject = {
        ...location,
        creator: responseUser.id as string,
      };
      const responseAddress = (await createAddress(addressObject)) as Addresses;

      const responseCreateCompany = (await createCompany({
        ...companyData,
        manager: responseUser.id as string,
        agent: agent?.id as string,
        location: responseAddress.id,
      })) as Companies;

      const responseCreateUser = (await createUser({
        user: { id: responseUser.id },
        agent: agent?.id as string,
        company: responseCreateCompany.id,
      })) as Users;

      await createCompanyUser({
        users_id: responseCreateUser.id,
        companies_id: responseCreateCompany.id,
      });

      const responseCreateMembership = (await createMembership({
        user: responseCreateUser.id,
      })) as Memberships;

      const responseCreateInvoice = (await createInvoice({
        recipient: 'company',
        company: responseCreateCompany.id,
        number_of_employees: companyData.number_of_employees,
      })) as Invoices;

      await createMembershipInvoice({
        memberships_id: responseCreateMembership.id,
        invoices_id: responseCreateInvoice.id,
      });

      await createInvoiceMembership({
        invoices_id: responseCreateInvoice.id,
        memberships_id: responseCreateMembership.id,
      });

      cleanupCompanyData();
      scrollToView('top-header');
    } catch (error) {
      console.log('FAILED!', error);
      setIsLoading(false);
      scrollToView('top-header');
    }
  };

  useEffect(() => {
    initForm();
    focusField(FOCUS_FIRST_FIELD);
    if (storedCompanyData.number_of_employees) {
      handlePaymentAmount(storedCompanyData.number_of_employees);
    }
    scrollToView('top-header');
  }, []);

  return (
    <Container mb={marginBetweenSections}>
      <Heading mt="20" mb="5">
        {t('form.titleAddCompany')}
      </Heading>

      {hasNoUniqueEmail && (
        <AlertBox
          status="error"
          title={t('common.alert.errorNoUniqueEmailTitle') || undefined}
          description={t('common.alert.errorNoUniqueEmailDescription')}
          onClose={() => setHasNoUniqueEmail(false)}
        />
      )}

      {successfullyAdded && (
        <AlertBox
          status="success"
          title={t('common.alert.successfullyRegisteredTitle') || undefined}
          description={t('common.alert.successfullyRegisteredDescription')}
          onClose={() => setSuccessfullyAdded(false)}
        />
      )}

      {!successfullyAdded && agent && <RegisterAgentInfo agent={agent} />}

      {!successfullyAdded && (
        <form onSubmit={handleSubmit}>
          <Stack mb={marginBetweenSections} spacing="5">
            <Heading size="md">{t('form.subtitlePersonResponsible')}</Heading>

            <User
              user={companyData.manager as DirectusUsers}
              onChange={(event) => handleChange(event, 'user')}
            />

            <Heading size="md">{t('form.subtitleCompanyInformation')}</Heading>

            <FormControl>
              <FormLabel htmlFor="name">{t('form.companyName')}</FormLabel>
              <Input
                id="name"
                type="text"
                value={companyData.name}
                onChange={(event) => handleChange(event, 'company')}
                required
              />
            </FormControl>

            <SimpleGrid columns={[1, 2]} spacing={2}>
              <FormControl>
                <FormLabel htmlFor="number_of_employees">
                  {t('form.numberOfEmployees')}
                </FormLabel>
                <NumberInput
                  id="number_of_employees"
                  value={companyData.number_of_employees}
                  onChange={handleNumberOfEmployees}
                  step={1}
                  min={1}
                  allowMouseWheel>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <FormHelperText>
                  {t('form.numberOfEmployeesHelp')}
                </FormHelperText>
              </FormControl>

              <FormControl>
                <FormLabel
                  htmlFor="paymentAmount"
                  textAlign={['left', 'center']}>
                  {t('form.paymentAmount')}
                </FormLabel>
                <SimpleGrid
                  columns={3}
                  spacing={2}
                  minHeight={12}
                  fontSize={'lg'}>
                  <Box alignSelf={'center'} textAlign={['left', 'right']}>
                    {t('common.year')}
                  </Box>
                  <Box alignSelf={'center'} textAlign={'right'}>
                    {paymentAmount}
                  </Box>
                  <Box alignSelf={'center'}>
                    .-&nbsp;{currentCurrency?.currency_code}
                    {!currentCurrency?.currency_code && <>EUR</>}
                  </Box>
                </SimpleGrid>
                <FormHelperText textAlign={['left', 'center']}>
                  {t('form.paymentAmountHelp')}
                </FormHelperText>
              </FormControl>
            </SimpleGrid>

            <Address
              address={companyData.location as Addresses}
              countries={countries}
              defaultCountry={country.iso_code}
              showHelperText={true}
              onChange={(event) => handleChange(event, 'address')}
            />

            <SwitchTermsAndConditions
              isChecked={isChecked}
              onChange={handleChecked}
            />

            <Button mt="4" size="lg" type="submit" isLoading={isLoading}>
              {t('form.registerCompany')}
            </Button>
          </Stack>
        </form>
      )}
    </Container>
  );
};
