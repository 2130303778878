import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  LanguageCodes,
  languageNamesInNationalLanguage,
} from '../../utils/language';

export const LanguageMenu = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const currentLanguage = i18next.resolvedLanguage;

  return (
    <Menu>
      <MenuButton as={Button} size="md" variant={['outline', 'ghost']}>
        {
          Object.keys(LanguageCodes)[
            Object.values(LanguageCodes).indexOf(
              currentLanguage as LanguageCodes
            )
          ]
        }
      </MenuButton>
      <MenuList minW="80px">
        {Object.entries(LanguageCodes).map(([key, value]) => (
          <MenuItem key={key} onClick={() => changeLanguage(value)}>
            {(languageNamesInNationalLanguage as any)[key]}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};
