import {
  Box,
  Button,
  Container,
  Heading,
  Image,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { BonavendoLogo } from '../BonavendoLogo/BonavendoLogo';
import ThinkingSmiley from '../../assets/thinking-smiley.png';
import { Imprint } from '../Imprint/Imprint';

export const Error = () => {
  const { t } = useTranslation();
  const logoColor = useColorModeValue('#D90404', '#F2F2F2');
  const marginBottom = 10;

  return (
    <Container maxW={'sm'}>
      <Box mt={[10, 20]} mb={marginBottom}>
        <Link href="/">
          <BonavendoLogo color={logoColor} margin="0 auto" />
        </Link>
      </Box>

      <Box mb={marginBottom} textAlign="center">
        <Heading>{t('error.404.title')}</Heading>
        <Text>{t('error.404.description')}</Text>
        <Image src={ThinkingSmiley} h="100" w="100" mx={'auto'} my={10} />
        <Link href="/" textDecoration="none">
          <Button>{t('common.home')}</Button>
        </Link>
      </Box>

      <Box mb={marginBottom} textAlign="center">
        <Trans i18nKey="common.noAccount">
          New to bonavendo?&nsbp;
          <Link href="/register-member" textDecoration="underline">
            Join Now!
          </Link>
        </Trans>
      </Box>

      <Imprint />
    </Container>
  );
};
