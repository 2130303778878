import {
  Box,
  VStack,
  Button,
  Flex,
  chakra,
  Grid,
  GridItem,
  Container,
  Text,
  Heading,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import {} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { globalDefaultContext } from '../../contexts/GlobalContext';
import { NavLink } from 'react-router-dom';
import { URL_REGISTER_MEMBER } from '../../utils/constant';

const { REACT_APP_BETA_PHASE_START_DATE } = process.env;

interface FeatureProps {
  active?: boolean;
  heading: string;
  text: string;
}

const optionsAmount = 24;
const options = Array.from(Array(24).keys());
const startDate = new Date(`${REACT_APP_BETA_PHASE_START_DATE}`);
const currentDate = new Date();
const timeBetween =
  currentDate.getMonth() -
  startDate.getMonth() +
  12 * (currentDate.getFullYear() - startDate.getFullYear());

const Feature = ({ active, heading, text }: FeatureProps) => {
  return (
    <GridItem
      border={active ? '2px solid' : '0 none'}
      borderColor={useColorModeValue('brand.dark', 'brand.light')}
      borderRadius={'base'}
      opacity={active ? '1' : '0.7'}
      p={4}
      textAlign={'center'}>
      <chakra.h3 fontSize="xl" fontWeight="600" whiteSpace={'nowrap'}>
        {heading}
      </chakra.h3>
      <chakra.p whiteSpace={'nowrap'}>{text}</chakra.p>
    </GridItem>
  );
};

export const BetaPhase = () => {
  const { t } = useTranslation();
  const currentOptions = optionsAmount - timeBetween + 1;
  const isBetaPhase = currentOptions > 0;

  return isBetaPhase ? (
    <Flex
      bgColor={useColorModeValue('yellow.200', 'red.800')}
      py={globalDefaultContext.marginBetweenSections}
      w={'full'}>
      <Container maxW={'4xl'}>
        <Grid
          templateColumns={{
            base: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(2, 1fr)',
          }}
          gap={4}
          mb={6}>
          <GridItem colSpan={1}>
            <VStack alignItems={['center', 'flex-start']} spacing={5}>
              <Heading>{t('beta.title')}</Heading>
              <Stack direction={['column', 'row']} spacing={5} align={'center'}>
                <Button as={NavLink} to={URL_REGISTER_MEMBER}>
                  {t('common.registerMember')}
                </Button>
                <Text>{t('beta.ctaSubline', { options: currentOptions })}</Text>
              </Stack>
            </VStack>
          </GridItem>
          <GridItem>
            <Flex>
              <Text>{t('beta.text')}</Text>
            </Flex>
          </GridItem>
        </Grid>

        <Box my={10}>
          <Heading fontSize={'2xl'} textAlign={['center', 'left']}>
            {t('beta.subtitle')}
          </Heading>
        </Box>

        <Grid
          templateColumns={{
            base: 'repeat(3, 1fr)',
            sm: 'repeat(4, 1fr)',
            md: 'repeat(6, 1fr)',
            lg: 'repeat(8, 1fr)',
          }}
          gap={{ base: '4' }}>
          {options.map((index) => (
            <Feature
              key={index}
              active={index + 1 === timeBetween}
              heading={`M ${index + 1}`}
              text={`${optionsAmount - index} Opt.`}
            />
          ))}
        </Grid>
      </Container>
    </Flex>
  ) : (
    <></>
  );
};
