import { useEffect, useState } from 'react';
import { readStates, readStatesTranslations } from '../services/directus';
import { DEFAULT_LANGUAGE, LanguageCodes } from '../utils/language';
import { States, StatesTranslations } from '../utils/types';

export const useStates = (currentLanguage: LanguageCodes) => {
  const [states, setStates] = useState<States[]>([]);

  const fetchStates = () =>
    readStates().then(async (responseStates: any) => {
      const statesData = responseStates.data;

      if (currentLanguage === DEFAULT_LANGUAGE) {
        setStates(statesData);
        return;
      }

      const responseStatesTranslations = await readStatesTranslations();
      const statesTranslationsData = responseStatesTranslations.data;

      statesTranslationsData?.map((translation: StatesTranslations) => {
        if (translation.languages_code === currentLanguage) {
          const statesIndex = statesData.findIndex(
            (state: States) => state.id === translation.states_id
          );

          statesData[statesIndex].name = translation.name;
          setStates(statesData as States[]);
        }
      });
    });

  useEffect(() => {
    fetchStates();
  }, [currentLanguage]);

  return states;
};
