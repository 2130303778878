import { createContext, ReactNode, useEffect, useState } from 'react';
import { LOCALSTORAGE_DEFAULT_IP_INFO } from '../utils/constant';
import { initialGeoIp } from '../utils/initial-values';
import { GeoIp } from '../utils/types';

export const GeoIpContext = createContext(initialGeoIp);

export const GeoIpProvider = ({ children }: { children: ReactNode }) => {
  const [ipInfo, setIpInfo] = useState(initialGeoIp);

  const storedIpInfo: GeoIp = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_DEFAULT_IP_INFO) ||
      JSON.stringify(initialGeoIp)
  );

  const fetchIpInfo = async () => {
    await fetch(`${process.env.REACT_APP_GEOIP_URL}`)
      .then((response) => response.json())
      .then((jsonResponse) => {
        console.log({ jsonResponse });
        localStorage.setItem(
          LOCALSTORAGE_DEFAULT_IP_INFO,
          JSON.stringify(jsonResponse)
        );
        setIpInfo(jsonResponse);
      });
  };

  useEffect(() => {
    if (storedIpInfo.country.names.en !== '') {
      setIpInfo(storedIpInfo);
    }
    if (storedIpInfo.country.names.en === '') {
      fetchIpInfo();
    }
  }, []);

  return (
    <GeoIpContext.Provider value={ipInfo}>{children}</GeoIpContext.Provider>
  );
};
