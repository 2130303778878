export enum LanguageCodes {
  de = 'de-DE',
  en = 'en-US',
  es = 'es-ES',
  fr = 'fr-FR',
  it = 'it-IT',
}

export const DEFAULT_LANGUAGE = LanguageCodes.de;

export const languageNamesInNationalLanguage = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
};
