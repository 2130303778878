import { Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const RegisterCompanyInfo = ({
  companyName,
}: {
  companyName: string;
}) => {
  const { t } = useTranslation();

  return (
    <Stack mb="5" spacing="3">
      <p>
        {t('common.sponsoredBy')} <strong>{companyName}</strong>!
      </p>
      <p>
        {t('common.sponsoredByDescription', {
          companyNameValue: companyName,
        })}
      </p>
    </Stack>
  );
};
