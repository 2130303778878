import { Box, BoxProps } from '@chakra-ui/react';

export const Card = (props: BoxProps) => (
  <Box
    boxShadow="md"
    maxW="520px"
    mx="0"
    my="5"
    overflow="hidden"
    rounded="base"
    w="full"
    {...props}
  />
);
