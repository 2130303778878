import { Logo } from '../Logo/Logo';

interface BonavendoLogoProps {
  color?: string;
  margin?: string;
  width?: string;
}

export const BonavendoLogo = ({
  color = '#ffffff',
  margin,
  width = '160px',
}: BonavendoLogoProps) => {
  return <Logo color={color} margin={margin} width={width} />;
};
