export const URL_ROOT = '/';
export const URL_PAGE = '/page';
export const URL_SEARCH = '/search';
export const URL_REGISTER_MEMBER = '/register-member';
export const URL_REGISTER_COMPANY = '/register-company';
export const URL_LOGIN = '/login';
export const URL_MEMBER = '/member';
export const URL_MEMBER_CARD = '/member-card';
export const URL_MEMBER_PROFILE = '/member-profile';
export const URL_MEMBER_SETTINGS = '/member-settings';
export const URL_DASHBOARD = '/dashboard';
export const URL_NOT_FOUND = '/404';
export const URL_IMPRINT = '/page/imprint';
export const URL_PRIVACY = '/page/privacy';
export const URL_TERMS = '/page/terms';
export const URL_ABOUT_US = '/page/about';
export const URL_STATUTES = '/page/statutes';
export const URL_CONTACT = '/page/contact';
export const URL_PRICING = '/page/pricing';
export const URL_CONFIRMATION = '/page/confirmation';
export const URL_HOW_IT_WORKS = '/page/howitworks';

export const LOCALSTORAGE_DEFAULT_IP_INFO = 'ipInfo';
export const LOCALSTORAGE_USER_DATA = 'userData';
export const LOCALSTORAGE_SEARCH_QUERY = 'searchQuery';

export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const AUTH_TOKEN = 'auth_token';
export const AUTH_EXPIRES_AT = 'auth_expires_at';
