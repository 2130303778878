import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { directusWithStaticToken } from '../services/directus';
import { useTranslation } from 'react-i18next';

import { Status } from '../utils/types';
import { Loading } from '../components/Loading/Loading';
import {
  PageCard,
  PageMetaProps,
  PageProps,
  PagesProps,
  PagesTranslationProps,
  PageTranslationProps,
} from '../components/PageCard/PageCard';
import { URL_NOT_FOUND } from '../utils/constant';

const { REACT_APP_ITEMS_PAGES, REACT_APP_ITEMS_PAGES_TRANSLATIONS } =
  process.env;

export const Page = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { i18n } = useTranslation();
  const [meta, setMeta] = useState<PageMetaProps>();
  const [page, setPage] = useState<PageTranslationProps>();
  const [pageTranslations, setPageTranslations] = useState<
    PageTranslationProps[]
  >([]);
  const currentLanguage = i18n.resolvedLanguage;
  const { t } = useTranslation();

  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    async function fetchData() {
      let pageResponse: PageProps;
      let pagesResponse: PagesProps;
      let pageTranslationsResponse: PagesTranslationProps;

      try {
        if (slug) {
          pagesResponse = (await directusWithStaticToken
            .items(`${REACT_APP_ITEMS_PAGES}`)
            .readByQuery({
              search: slug,
              filter: {
                slug: {
                  _eq: slug,
                },
              },
            })) as PagesProps;

          pageResponse = pagesResponse.data[0];

          if (pageResponse.status !== Status.published) {
            navigate('/404', { replace: true });
          }

          setMeta({
            slug: pageResponse.slug,
            status: pageResponse.status,
            date_updated: pageResponse.date_updated,
            display_date_updated: pageResponse.display_date_updated,
            cover_image: pageResponse.cover_image,
            translations: pageResponse.translations,
          });

          const defaultPage = {
            languages_id: pageResponse.languages_id,
            title: pageResponse.title,
            body: pageResponse.body,
          };

          pageTranslationsResponse = (await directusWithStaticToken
            .items(`${REACT_APP_ITEMS_PAGES_TRANSLATIONS}`)
            .readMany(pageResponse.translations, {
              fields: ['languages_id', 'title', 'body'],
            })) as PagesTranslationProps;

          const allTranslations = [...pageTranslationsResponse.data];
          allTranslations.unshift(defaultPage);

          setPageTranslations(allTranslations);
        }
      } catch (error) {
        navigate(`${URL_NOT_FOUND}`, { replace: true });
      }
    }

    fetchData();
  }, [navigate, slug, setMeta, setPageTranslations]);

  useEffect(() => {
    if (pageTranslations.length > 0) {
      const currentTranslation = pageTranslations.filter((translation) => {
        return String(translation.languages_id) === currentLanguage;
      });

      if (currentTranslation.length > 0) {
        setPage(currentTranslation[0]);
      }
    }
  }, [setPage, page, setPageTranslations, pageTranslations, currentLanguage]);

  return (
    <main className="page">
      <section>
        {!page && <Loading text={t('common.loadingPage')} />}
        {meta && meta.status === Status.published && page && (
          <PageCard meta={meta} page={page} />
        )}
      </section>
    </main>
  );
};
