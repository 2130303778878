import { Button, Container, Heading, Stack } from '@chakra-ui/react';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../contexts/GlobalContext';
import { GeoIpContext } from '../../services/geoip';
import { useCountries } from '../../hooks/countries';
import {
  createAddress,
  createCompanyUser,
  createInvoice,
  createInvoiceMembership,
  createMembership,
  createMembershipInvoice,
  createUser,
  readInvoice,
  registerUser,
  updateMembership,
} from '../../services/directus';
import { scrollToView } from '../../utils/browser';
import { focusField, initForm } from '../../utils/form';
import { initialRegisterUser } from '../../utils/initial-values';
import { LanguageCodes } from '../../utils/language';
import {
  Addresses,
  DirectusUsers,
  InvoiceStatus,
  Invoices,
  MembershipStatus,
  Memberships,
  Users,
} from '../../utils/types';
import { Address } from '../Address/Address';
import { AlertBox } from '../AlertBox/AlertBox';
import { RegisterAgentInfo } from '../RegisterAgentInfo/RegisterAgentInfo';
import { RegisterCompanyInfo } from '../RegisterCompanyInfo/RegisterCompanyInfo';
import { SwitchTermsAndConditions } from '../SwitchTermsAndConditions/SwitchTermsAndConditions';
import { User } from '../User/User';

const LOCALSTORAGE_REGISTER_DATA = 'bonavendoRegisterData';
const LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS =
  'bonavendoTermsAndConditionsChecked';
const FOCUS_FIRST_FIELD = 'firstName';

interface RegisterMemberFormProps {
  agent?: DirectusUsers;
  companyId: number;
  companyName: string;
  isDisplayingAddress: boolean;
}

export const RegisterMemberForm = ({
  agent,
  companyId,
  companyName,
  isDisplayingAddress = false,
}: RegisterMemberFormProps) => {
  const [hasNoUniqueEmail, setHasNoUniqueEmail] = useState(false);
  const { i18n, t } = useTranslation();
  const { marginBetweenSections } = useContext(GlobalContext);
  const { country } = useContext(GeoIpContext);
  const countries = useCountries(i18n.language as LanguageCodes);

  const storedUserData: Users = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_REGISTER_DATA) ||
      JSON.stringify(initialRegisterUser)
  );
  const storedTermsAndConditionsChecked = JSON.parse(
    localStorage.getItem(LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS) || 'false'
  );

  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(
    storedTermsAndConditionsChecked === true || false
  );
  const [successfullyRegistered, setSuccessfullyRegistered] = useState(false);
  const [registerData, setRegisterData] = useState<Users>({
    user: storedUserData.user,
    address: storedUserData.address,
  });

  const defaultCountry = countries?.find((c) => c.code === country.iso_code);

  const cleanupRegisterData = () => {
    setRegisterData({ ...initialRegisterUser });
    setSuccessfullyRegistered(true);
    setIsLoading(false);
    setIsChecked(false);
    localStorage.removeItem(LOCALSTORAGE_REGISTER_DATA);
    localStorage.removeItem(LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS);
  };

  const handleChecked = () => {
    setIsChecked(!isChecked);
    localStorage.setItem(
      LOCALSTORAGE_CHECKED_TERMS_AND_CONDITIONS,
      JSON.stringify(!isChecked)
    );
  };

  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    type: 'user' | 'address'
  ) => {
    const user = registerData.user as DirectusUsers;
    const userObject = {
      ...user,
      ...(type === 'user'
        ? { [event.currentTarget.id]: event.currentTarget.value }
        : {}),
    };

    const address = registerData.address as Addresses;
    const addressObject = {
      ...address,
      ...(type === 'address'
        ? { [event.currentTarget.id]: event.currentTarget.value }
        : {}),
    };

    const changedRegisterData = {
      ...registerData,
      user: {
        ...userObject,
      },
      address: {
        ...addressObject,
      },
    };

    setRegisterData(changedRegisterData as Users);
    localStorage.setItem(
      LOCALSTORAGE_REGISTER_DATA,
      JSON.stringify(changedRegisterData)
    );
  };

  const handleSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const user = registerData.user as DirectusUsers;
      const responseUser = (await registerUser({
        ...user,
        language: i18n.language,
      }).catch((err) => {
        for (const error of err.errors) {
          if (error.message.includes('email')) {
            setHasNoUniqueEmail(true);
          }
        }
      })) as DirectusUsers;

      const location = registerData.address as Addresses;
      const addressObject = {
        ...location,
        creator: responseUser.id as string,
      };
      const responseAddress = (await createAddress(addressObject)) as Addresses;

      const responseCreateUser = (await createUser({
        user: { id: responseUser.id },
        address:
          responseAddress.id === 0 ? undefined : { id: responseAddress.id },
        agent: agent?.id as string,
        company: companyId ? { id: companyId } : undefined,
      })) as Users;

      if (companyId) {
        await createCompanyUser({
          users_id: responseCreateUser.id,
          companies_id: companyId,
        });
      }

      const responseCreateMembership = (await createMembership({
        user: responseCreateUser.id,
      })) as Memberships;

      // create invoice
      if (companyId === 0 && responseCreateMembership.id) {
        const responseCreateInvoice = (await createInvoice({
          recipient: companyId ? 'company' : 'user',
          company: companyId ? companyId : undefined,
          user: companyId ? undefined : responseCreateUser?.id,
        })) as Invoices;

        await createMembershipInvoice({
          memberships_id: responseCreateMembership.id,
          invoices_id: responseCreateInvoice.id,
        });

        await createInvoiceMembership({
          invoices_id: responseCreateInvoice.id,
          memberships_id: responseCreateMembership.id,
        });
      }

      // update invoice
      if (companyId !== 0 && responseCreateMembership?.id) {
        const responseReadInvoice = (await readInvoice(companyId)) as Invoices;

        if (responseReadInvoice.status === InvoiceStatus.paid) {
          updateMembership(responseCreateMembership.id, {
            status: MembershipStatus.active,
          });
        }

        await createMembershipInvoice({
          memberships_id: responseCreateMembership.id,
          invoices_id: responseReadInvoice.id,
        });

        await createInvoiceMembership({
          invoices_id: responseReadInvoice.id,
          memberships_id: responseCreateMembership.id,
        });
      }

      cleanupRegisterData();
      scrollToView('top-header');
    } catch (error) {
      console.log('FAILED!', error);
      setIsLoading(false);
      scrollToView('top-header');
    }
  };

  useEffect(() => {
    initForm();
    focusField(FOCUS_FIRST_FIELD);
    scrollToView('top-header');
  }, []);

  return (
    <Container>
      <Heading mt="20" mb="5">
        {t('form.titleRegisterMember')}
      </Heading>

      {hasNoUniqueEmail && (
        <AlertBox
          status="error"
          title={t('common.alert.errorNoUniqueEmailTitle') || undefined}
          description={t('common.alert.errorNoUniqueEmailDescription')}
          onClose={() => setHasNoUniqueEmail(false)}
        />
      )}

      {successfullyRegistered && (
        <AlertBox
          status="success"
          title={t('common.alert.successfullyRegisteredTitle') || undefined}
          description={t('common.alert.successfullyRegisteredDescription')}
          onClose={() => setSuccessfullyRegistered(false)}
        />
      )}

      {!successfullyRegistered && agent && <RegisterAgentInfo agent={agent} />}

      {!successfullyRegistered && companyName && (
        <RegisterCompanyInfo companyName={companyName} />
      )}

      {!successfullyRegistered && (
        <form onSubmit={handleSubmit}>
          <Stack mb={marginBetweenSections} spacing="5">
            <User
              user={registerData.user as DirectusUsers}
              onChange={(event) => handleChange(event, 'user')}
            />

            {isDisplayingAddress && (
              <Address
                address={registerData.address as Addresses}
                countries={countries}
                defaultCountry={country.iso_code}
                showHelperText={true}
                onChange={(event) => handleChange(event, 'address')}
              />
            )}

            <SwitchTermsAndConditions
              isChecked={isChecked}
              onChange={handleChecked}
            />

            <Button
              mt="4"
              size="lg"
              type="submit"
              disabled={!isChecked}
              isLoading={isLoading}>
              {companyId === 0
                ? t('form.registerWithCosts')
                : t('form.register')}
            </Button>
          </Stack>
        </form>
      )}
    </Container>
  );
};
