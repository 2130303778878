import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { CheckIcon } from '@chakra-ui/icons';

import { useTranslation } from 'react-i18next';
import { ConfirmationStatus, DirectusUsers, Status } from '../../utils/types';
import { readUser, updateDirectusUser } from '../../services/directus';
import { BonavendoLogo } from '../BonavendoLogo/BonavendoLogo';
import { Loading } from '../Loading/Loading';
import { Imprint } from '../Imprint/Imprint';

export const Confirmation = () => {
  const [confirmationState, setConfirmationState] = useState(
    ConfirmationStatus.unconfirmed
  );
  const [loginUrl, setLoginUrl] = useState('/login');
  const [query] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const id = query.get('id');
  const { i18n, t } = useTranslation();

  useEffect(() => {
    !id && setIsLoading(false);
    id &&
      readUser(id as string)
        .then((response) => {
          const res = { ...response } as DirectusUsers;

          if (res.language !== i18n.language) {
            i18n.changeLanguage(res.language);
          }

          if (res.role === process.env.REACT_APP_MEMBER_MANAGER_ROLE_ID) {
            setLoginUrl(process.env.REACT_APP_DIRECTUS_URL + 'admin/login');
          }

          if (res.status === Status.active) {
            setConfirmationState(ConfirmationStatus.alreadyConfirmed);
            setIsLoading(false);
          }

          if (res.status === Status.draft) {
            updateDirectusUser(id as string, Status.active)
              .then((response) => {
                const res: DirectusUsers = { ...response };
                if (res.status === Status.active) {
                  setConfirmationState(ConfirmationStatus.confirmed);
                }
                setIsLoading(false);
              })
              .catch(() => {
                setIsLoading(false);
              });
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
  }, []);

  return (
    <Container my={20} maxW={360}>
      <Box mt="20" mb="10">
        <Link href="/">
          <BonavendoLogo
            color={useColorModeValue('#e63e62', 'white')}
            margin="0 auto"
          />
        </Link>
      </Box>

      {isLoading && <Loading text={t('common.loadingPage')} />}

      <Flex
        flexDirection={'column'}
        textAlign={'center'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={10}
        height={'100%'}>
        {!isLoading && confirmationState === ConfirmationStatus.unconfirmed && (
          <>
            <Heading>{t('confirmation.unconfirmedHeadline')}</Heading>
            <Alert status="error">
              <AlertIcon />
              {t('confirmation.unconfirmedDescription')}
            </Alert>
            <Box>
              <Link href="/" textDecoration="none">
                <Button>{t('common.home')}</Button>
              </Link>
            </Box>
          </>
        )}

        {!isLoading && confirmationState === ConfirmationStatus.confirmed && (
          <>
            <Heading>{t('confirmation.confirmedHeadline')}</Heading>
            <CheckIcon boxSize={10} color={'green.300'} />
            <Text>{t('confirmation.confirmedDescription')}</Text>
            <Link href={loginUrl} textDecoration="none">
              <Button>{t('common.login')}</Button>
            </Link>
          </>
        )}

        {!isLoading &&
          confirmationState === ConfirmationStatus.alreadyConfirmed && (
            <>
              <Heading>{t('confirmation.alreadyConfirmedHeadline')}</Heading>
              <Text>{t('confirmation.alreadyConfirmedDescription')}</Text>
              <Link href={loginUrl} textDecoration="none">
                <Button>{t('common.login')}</Button>
              </Link>
            </>
          )}
      </Flex>

      <Imprint />
    </Container>
  );
};
