import { useEffect, useState } from 'react';
import {
  readCurrencies,
  readCurrenciesTranslations,
} from '../services/directus';
import { DEFAULT_LANGUAGE, LanguageCodes } from '../utils/language';
import { Currencies, CurrenciesTranslations } from '../utils/types';

export const useCurrencies = (currentLanguage: LanguageCodes) => {
  const [currencies, setCurrencies] = useState<Currencies[]>([]);

  const fetchCurrencies = () =>
    readCurrencies().then(async (responseCurrencies: any) => {
      const currenciesData = responseCurrencies.data;

      if (currentLanguage === DEFAULT_LANGUAGE) {
        setCurrencies(currenciesData);
        return;
      }

      const responseCurrenciesTranslations = await readCurrenciesTranslations();
      const currenciesTranslationsData = responseCurrenciesTranslations.data;

      currenciesTranslationsData?.map((translation: CurrenciesTranslations) => {
        if (translation.languages_code === currentLanguage) {
          const currenciesIndex = currenciesData.findIndex(
            (currency: Currencies) => currency.id === translation.currencies_id
          );

          currenciesData[currenciesIndex].name = translation.currency_name;
          setCurrencies(currenciesData as Currencies[]);
        }
      });
    });

  useEffect(() => {
    fetchCurrencies();
  }, [currentLanguage]);

  return currencies;
};
