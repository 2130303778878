import { useEffect, useState } from 'react';
import { readCountries, readCountriesTranslations } from '../services/directus';
import { DEFAULT_LANGUAGE, LanguageCodes } from '../utils/language';
import { Countries, CountriesTranslations } from '../utils/types';

export const useCountries = (currentLanguage: LanguageCodes) => {
  const [countries, setCountries] = useState<Countries[]>([]);

  const fetchCountries = () =>
    readCountries().then(async (responseCountries: any) => {
      const countriesData = responseCountries.data;

      if (currentLanguage === DEFAULT_LANGUAGE) {
        setCountries(countriesData);
        return;
      }

      const responseCountriesTranslations = await readCountriesTranslations();
      const countriesTranslationsData = responseCountriesTranslations.data;

      countriesTranslationsData?.map((translation: CountriesTranslations) => {
        if (translation.languages_code === currentLanguage) {
          const countriesIndex = countriesData.findIndex(
            (country: Countries) => country.id === translation.countries_id
          );

          countriesData[countriesIndex].name = translation.name;
          setCountries(countriesData as Countries[]);
        }
      });
    });

  useEffect(() => {
    fetchCountries();
  }, [currentLanguage]);

  return countries;
};
