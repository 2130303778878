import { Box, Button, Center, Container, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { BonavendoLogo } from '../BonavendoLogo/BonavendoLogo';
import { NavLink } from 'react-router-dom';
import { URL_MEMBER } from '../../utils/constant';
import { useContext } from 'react';
import { AuthContext } from '../../services/auth';
import { MembershipStatus } from '../../utils/types';
import { Loading } from '../Loading/Loading';

export const MemberCard = () => {
  const { t } = useTranslation();
  const { isLoading, userData } = useContext(AuthContext);
  const memberSince = new Date(userData.date_created).toLocaleDateString();

  let statusColor = 'red.300';
  switch (userData.status) {
    case MembershipStatus.active:
      statusColor = 'green.300';
      break;
    case MembershipStatus.archived:
      statusColor = 'gray.500';
      break;
    case MembershipStatus.expired:
      statusColor = 'red.300';
      break;
    case MembershipStatus.pending:
      statusColor = 'orange.300';
      break;
    case undefined:
      statusColor = 'red.300';
      break;
    default:
      break;
  }

  return (
    <Container maxWidth={'360px'}>
      <Flex
        className={`member-card ${isLoading ? '' : 'loaded'}`}
        flexDirection={'column'}
        justifyContent={`${isLoading ? '' : 'space-between'}`}
        backgroundColor="brand.darkRed"
        borderColor="brand.red"
        borderRadius="3xl"
        borderWidth="2px"
        color="white"
        textAlign="center"
        height={['85vh', 'auto']}
        minHeight={'580px'}
        my="4"
        pt="8">
        <Box
          backgroundColor={'black'}
          borderColor="brand.red"
          borderRadius="3xl"
          borderWidth="2px"
          h="2"
          w="16"
          mb="8"
          mx="auto"></Box>

        {isLoading && (
          <Box pt={'10vh'}>
            <Loading text={t('common.loadingCard')} />
          </Box>
        )}

        {!isLoading && (
          <>
            <Center mb="4">
              <BonavendoLogo width="70%" />
            </Center>

            <Box mb="8">
              <Text fontSize={'lg'} fontStyle="italic">
                {t('common.claimPart1')}
                <br />
                {t('common.claimPart2')}
              </Text>
            </Box>

            <Flex mb="8" px="4" justifyContent="center" alignItems="center">
              <Box width="80%">
                <Text noOfLines={2} fontSize={'2xl'} fontWeight="bold" mb="2">
                  {userData.first_name} {userData.last_name}
                </Text>
                <Text fontSize="xs" noOfLines={1}>
                  {userData.email}
                </Text>
              </Box>
            </Flex>

            <Text fontSize={'2xl'} color={statusColor} fontWeight={600} mb="8">
              {t('common.status')}:
              <br />
              {userData.status !== undefined && t('status.' + userData.status)}
            </Text>

            <Box mb="8" fontSize={'xl'}>
              {t('common.memberSince')}
              <br />
              {memberSince !== 'Invalid Date' && memberSince}
            </Box>

            <Box color="brand.white" fontSize="2xl" p="4">
              #{userData.id}
            </Box>
          </>
        )}
      </Flex>
      <Flex justifyContent={'center'}>
        <Button as={NavLink} to={URL_MEMBER}>
          Zurück
        </Button>
      </Flex>
    </Container>
  );
};
