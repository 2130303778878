import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Link, Stack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { DirectusUsers } from '../../utils/types';

export const RegisterAgentInfo = ({ agent }: { agent: DirectusUsers }) => {
  const { t } = useTranslation();

  return (
    <Stack mb="5" spacing="3">
      <p>
        {t('common.yourAgent')}:&nbsp;
        <strong>
          {agent.first_name} {agent.last_name}
        </strong>
      </p>
      <p>
        {t('common.yourAgentDescription')}{' '}
        <Link color={'blue.600'} href={`mailto:${agent.email}`} isExternal>
          {t('common.yourAgentEmailContact')}
          <ExternalLinkIcon mx="1" />
        </Link>
      </p>
    </Stack>
  );
};
