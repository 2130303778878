import { About } from '../components/About/About';
import { BetaPhase } from '../components/BetaPhase/BetaPhase';
import { Features } from '../components/Features/Features';
import { Hero } from '../components/Hero/Hero';

export const Home = () => {
  return (
    <main className="start">
      <section>
        <Hero />
        <About />
        <Features />
        <BetaPhase />
      </section>
    </main>
  );
};
