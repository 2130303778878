import { MouseEvent } from 'react';
import {
  AspectRatio,
  Box,
  Icon,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';

export interface TileProps {
  title: string;
  icon: IconType;
  onClick?: (event: MouseEvent) => void;
}

export const Tile = ({ title, icon, onClick }: TileProps) => {
  return (
    <AspectRatio minWidth={100} maxWidth={300} ratio={1} onClick={onClick}>
      <Box
        bg={useColorModeValue('gray.100', 'gray.900')}
        border={'1px solid'}
        borderColor={useColorModeValue('white', 'gray.700')}
        boxShadow={'md'}
        borderRadius={'xxl'}
        overflow={'hidden'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        gap={2}
        _hover={{
          bg: useColorModeValue('gray.200', 'gray.800'),
          cursor: 'pointer',
        }}>
        <Icon
          as={icon}
          h={12}
          w={12}
          color={useColorModeValue('red.800', 'gray.100')}
        />
        <Text fontSize={'sm'} color={useColorModeValue('red.800', 'gray.100')}>
          {title}
        </Text>
      </Box>
    </AspectRatio>
  );
};
