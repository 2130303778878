import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import Markdown from 'markdown-to-jsx';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const passwordRegex =
  '^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$';

interface PasswordProps {
  value: string;
  showHelperText?: boolean;
  applyRegex?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Password = ({
  value,
  showHelperText = false,
  applyRegex = false,
  onChange,
}: PasswordProps) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <FormControl>
      <FormLabel htmlFor="password">{t('form.password')}</FormLabel>
      <InputGroup>
        <Input
          id="password"
          onChange={onChange}
          pattern={applyRegex ? passwordRegex : undefined}
          required
          type={showPassword ? 'text' : 'password'}
          value={value}
        />
        <InputRightElement w="auto" mr="1">
          <Button
            size="md"
            variant={'ghost'}
            onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <Icon as={FaEye} /> : <Icon as={FaEyeSlash} />}
          </Button>
        </InputRightElement>
      </InputGroup>
      {showHelperText && (
        <>
          <FormHelperText>{t('form.passwordHelp')}</FormHelperText>
          <FormHelperText>
            <Markdown
              options={{
                overrides: {
                  a: {
                    component: 'a',
                    props: {
                      style: { color: 'var(--chakra-colors-blue-600)' },
                      rel: 'noopener noreferrer',
                      target: '_blank',
                    },
                  },
                },
              }}>
              {t('form.passwordHint')}
            </Markdown>
          </FormHelperText>
        </>
      )}
    </FormControl>
  );
};
