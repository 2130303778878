import { Error } from '../components/Error/Error';

export const NotFound = () => {
  return (
    <main className="error">
      <section>
        <Error />
      </section>
    </main>
  );
};
