import {
  Box,
  Container,
  Heading,
  Image,
  Link,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Markdown from 'markdown-to-jsx';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import GlobalContext from '../../contexts/GlobalContext';
import { Status } from '../../utils/types';
import { getAssetURL } from '../../utils/get-asset-url';
import { LanguageCodes } from '../../utils/language';
import { Card } from '../Card/Card';

export interface PageMetaProps {
  slug: string;
  status: Status;
  date_updated: string;
  display_date_updated: boolean;
  cover_image: string;
  translations: number[];
}

export interface PageTranslationProps {
  languages_id: LanguageCodes;
  title: string;
  body: string;
}

export interface PagesTranslationProps {
  data: PageTranslationProps[];
}

export interface PageProps extends PageMetaProps, PageTranslationProps {}

export interface PagesProps {
  data: PageProps[];
}

interface PageCardProps {
  meta: PageMetaProps;
  page: PageTranslationProps;
}

export const PageCard = ({ meta, page }: PageCardProps) => {
  const { t } = useTranslation();
  const { marginBetweenSections } = useContext(GlobalContext);

  return (
    <Container>
      <Card
        mb={marginBetweenSections}
        bgColor={useColorModeValue('yellow.50', 'red.800')}>
        {meta.cover_image && (
          <Box
            maxH="400px"
            h="auto"
            w="100%"
            mb="5"
            overflow="hidden"
            borderRadius="sm">
            <Image
              src={getAssetURL(meta.cover_image) || undefined}
              objectFit="cover"
              h="100%"
              w="100%"
              zIndex="1"
              maxH="inherit"
            />
          </Box>
        )}
        <Heading my="5" px="5">
          {page.title}
        </Heading>
        <Stack px="5" pb="5" spacing={3}>
          <Markdown
            options={{
              overrides: {
                p: { component: Text },
                a: { component: Link },
              },
              wrapper: Stack,
            }}>
            {page.body}
          </Markdown>
        </Stack>
        {meta.display_date_updated && (
          <Text my="3" px="5" pb="5">
            {t('common.lastModified', {
              date: new Date(meta.date_updated).toLocaleString(
                page.languages_id,
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: 'numeric',
                }
              ),
            })}
          </Text>
        )}
      </Card>
    </Container>
  );
};
