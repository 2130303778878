import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { icon, LatLngTuple } from 'leaflet';
import { Box, Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import 'leaflet/dist/leaflet.css';
import './map.scss';
import { useTranslation } from 'react-i18next';
import { Addresses, Companies } from '../../utils/types';

const Icon = icon({
  iconUrl: '/bonavendo-map-pin.svg',
  iconSize: [30, 38],
});

interface MapProps {
  company: Companies;
  address: Addresses;
  isPopupOpen?: boolean;
}

export const Map = ({ company, address, isPopupOpen = false }: MapProps) => {
  const { t } = useTranslation();
  const coordinates: LatLngTuple = [
    // @ts-ignore
    company?.map?.coordinates[1],
    // @ts-ignore
    company?.map?.coordinates[0],
  ];

  // to open popup on load
  const openPopup = () => {
    setTimeout(() => {
      const element = document.querySelector('.leaflet-marker-icon');
      if (element) {
        //@ts-ignore
        element.click();
      }
    }, 300);
  };

  return (
    <Box h={[300, 400, 500]}>
      {coordinates && (
        <MapContainer
          center={coordinates}
          zoom={13}
          scrollWheelZoom={false}
          whenReady={() => isPopupOpen && openPopup()}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={coordinates as LatLngTuple} icon={Icon}>
            <Popup>
              <address>
                <strong>{company.name}</strong>
                <br />
                {address.address_area && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: address.address_area.replace(/\r?\n/g, '<br />'),
                    }}
                  />
                )}
                {address.address_area && <br />}
                {address.postal_code} {address.city_location}
                {address.state && (
                  <>
                    ,&nbsp;
                    {`${address.state}`}
                  </>
                )}
                {address.country && (
                  <>
                    <br />
                    {`${address.country}`}
                  </>
                )}
              </address>
              <Link variant="outline" href={`geo:${coordinates}`} isExternal>
                {t('common.calculateRoute')} <ExternalLinkIcon />
              </Link>
            </Popup>
          </Marker>
        </MapContainer>
      )}
    </Box>
  );
};
