import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { mode } from '@chakra-ui/theme-tools';

export const Input: ComponentStyleConfig = {
  parts: ['field'],
  variants: {
    outline: (props) => ({
      field: {
        backgroundColor: mode('brand.light', 'red.800')(props),
        border: '2px solid',
        borderColor: mode('brand.dark', 'brand.light')(props),
        opacity: '0.8',
        _placeholder: {
          color: mode('blackAlpha.600', 'whiteAlpha.700')(props),
        },
        _hover: {
          borderColor: mode('black', 'white')(props),
        },
        _focus: {
          borderColor: mode('black', 'white')(props),
          boxShadow: 'none',
          color: mode('black', 'white')(props),
          opacity: '1',
        },
        _focusVisible: {
          backgroundColor: mode('white', 'red.800')(props),
          outline: '2px solid rgba(255, 255, 255, 0.5)',
        },
      },
      addon: {
        color: mode('brand.dark', 'brand.light')(props),
        opacity: '0.8',
        h: '5',
        w: '5',
      },
    }),
  },
  defaultProps: {
    size: 'lg',
    variant: 'outline',
  },
};

export default Input;
