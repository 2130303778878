import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { bonavendoTheme } from './theme/theme';
import './i18n/config';
import './theme/custom.scss';

import GlobalContext, { globalDefaultContext } from './contexts/GlobalContext';

import { GeoIpProvider } from './services/geoip';
import { AuthProvider } from './services/auth';
import { App } from './App';

const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');
const root = createRoot(container);

root.render(
  <StrictMode>
    <ColorModeScript
      initialColorMode={bonavendoTheme.config.initialColorMode}
    />
    <ChakraProvider theme={bonavendoTheme}>
      <GlobalContext.Provider value={globalDefaultContext}>
        <GeoIpProvider>
          <AuthProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AuthProvider>
        </GeoIpProvider>
      </GlobalContext.Provider>
    </ChakraProvider>
  </StrictMode>
);
