import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  MdOutlineLogin,
  MdPersonAddAlt1,
  MdAdd,
  MdEuroSymbol,
  MdOutlineCardMembership,
  MdOutlineLogout,
} from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import {
  URL_LOGIN,
  URL_MEMBER_CARD,
  URL_PRICING,
  URL_REGISTER_COMPANY,
  URL_REGISTER_MEMBER,
} from '../../utils/constant';
import { useContext } from 'react';
import { AuthContext } from '../../services/auth';

export const HeaderMenu = () => {
  const { t } = useTranslation();
  const { isAuthenticated, logout } = useContext(AuthContext);

  const textColor = useColorModeValue('gray.900', 'white');

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            size="md"
            as={IconButton}
            aria-label="Options"
            icon={
              isOpen ? <CloseIcon h="4" w="6" /> : <HamburgerIcon h="6" w="6" />
            }
            variant="outline"
          />
          <MenuList color={textColor}>
            {isAuthenticated ? (
              <MenuItem as={NavLink} to={URL_MEMBER_CARD}>
                <Icon as={MdOutlineCardMembership} mr="2" />
                {t('common.memberCard')}
              </MenuItem>
            ) : (
              <MenuItem as={NavLink} to={URL_REGISTER_MEMBER}>
                <Icon as={MdPersonAddAlt1} mr="2" />
                {t('common.registerMember')}
              </MenuItem>
            )}
            <MenuItem as={NavLink} to={URL_REGISTER_COMPANY}>
              <Icon as={MdAdd} mr="2" />
              {t('common.registerCompany')}
            </MenuItem>
            <MenuItem as={NavLink} to={URL_PRICING}>
              <Icon as={MdEuroSymbol} mr="2" />
              {t('common.pricing')}
            </MenuItem>
            {isAuthenticated ? (
              <MenuItem onClick={logout}>
                <Icon as={MdOutlineLogout} mr="2" />
                {t('common.logout')}
              </MenuItem>
            ) : (
              <MenuItem as={NavLink} to={URL_LOGIN}>
                <Icon as={MdOutlineLogin} mr="2" />
                {t('common.login')}
              </MenuItem>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
