import {
  Badge,
  Box,
  Heading,
  Link,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { Link as routeLink } from 'react-router-dom';
import { Companies } from '../../utils/types';

export interface SearchCardProps {
  company: Companies;
}

export const SearchCard = ({ company }: SearchCardProps) => {
  return (
    <Box bgColor={useColorModeValue('yellow.50', 'red.800')} p={5}>
      <Link
        as={routeLink}
        to={'/' + company.slug}
        _hover={{ textDecoration: 'none' }}
        display={'block'}>
        <Heading size={'md'}>{company.name}</Heading>

        {company.advantages &&
          company.advantages.map((tag) => (
            <Badge key={tag} variant={'outline'} mr={'2'} colorScheme={'red'}>
              {tag}
            </Badge>
          ))}
      </Link>

      <Text>{company.intro}</Text>
    </Box>
  );
};
