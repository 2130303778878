import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SearchList } from '../components/SearchList/SearchList';
import { readCompanies } from '../services/directus';
import { Companies } from '../utils/types';

export const Search = () => {
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [matches, setMatches] = useState<Companies[]>([]);
  const navigate = useNavigate();

  const { search } = useLocation();
  const queryParameters = new URLSearchParams(search);
  const query = queryParameters.get('q') || '';

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setInput(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    navigate('/search?q=' + input);
  };

  useEffect(() => {
    setInput(query);
    setMatches([]);

    if (query !== '') {
      setIsLoading(true);

      const keywords = query.split(' ');
      const array: Companies[] = [];

      Promise.all(
        keywords.map((keyword) =>
          readCompanies(keyword).then((response) => {
            if (response.data) {
              response.data.forEach((item) => {
                array.push(item);
              });

              // filter out duplicate items by id
              const filteredItems = array.filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.id === value.id)
              );

              return filteredItems;
            } else {
              return [];
            }
          })
        )
      )
        .then((matchesListArray) => {
          // combine all matches lists into a single list
          const matches = Array.prototype.concat(...matchesListArray);

          setMatches(matches);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [query]);

  return (
    <main className="search">
      <section>
        <SearchList
          query={input}
          results={matches}
          isLoading={isLoading}
          onInput={(event) => handleInput(event)}
          onSubmit={(event) => handleSubmit(event)}
        />
      </section>
    </main>
  );
};
