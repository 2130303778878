import { useEffect, useState } from 'react';
import { RegisterMemberForm } from '../components/RegisterMemberForm/RegisterMemberForm';
import { readCompany, readUser } from '../services/directus';
import { Companies, DirectusUsers, Status } from '../utils/types';

export const RegisterMember = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const aid = queryParams.get('aid'); // agentId
  const cid = queryParams.get('cid'); // companyId
  const [agent, setAgent] = useState<DirectusUsers>();
  const [companyId, setCompanyId] = useState(0);
  const [companyName, setCompanyName] = useState('');
  const [isDisplayingAddress, setIsDisplayingAddress] = useState(
    cid ? false : true
  );

  useEffect(() => {
    if (aid) {
      readUser(aid)
        .then((responseUser) => {
          if (responseUser) {
            const userAgent: DirectusUsers = {
              id: aid,
              first_name: responseUser.first_name || undefined,
              last_name: responseUser.last_name || undefined,
              email: responseUser.email || undefined,
            };

            setAgent(userAgent);
          }
        })
        .catch(() => {
          setAgent(undefined);
        });
    }
    if (cid) {
      readCompany(cid)
        .then((response) => {
          const res = response.data as Companies[];
          const responseCompany = res[0];

          if (
            responseCompany &&
            (responseCompany.status === Status.active ||
              responseCompany.status === Status.published)
          ) {
            responseCompany.id && setCompanyId(responseCompany.id);
            responseCompany.name && setCompanyName(responseCompany.name);
            setIsDisplayingAddress(false);
            if (responseCompany.agent) {
              responseCompany.agent &&
                readUser(responseCompany.agent as string).then(
                  (responseUser) => {
                    const userAgent: DirectusUsers = {
                      id: responseCompany.agent as string,
                      first_name: responseUser?.first_name || '',
                      last_name: responseUser?.last_name || '',
                      email: responseUser?.email || '',
                    };

                    setAgent(userAgent);
                  }
                );
            }
          } else {
            setAgent(undefined);
            setCompanyId(0);
            setIsDisplayingAddress(true);
          }
        })
        .catch(() => {
          setAgent(undefined);
          setCompanyId(0);
          setIsDisplayingAddress(true);
        });
    }
  }, []);

  return (
    <main className="register-member">
      <section>
        <RegisterMemberForm
          agent={agent}
          companyId={companyId}
          companyName={companyName}
          isDisplayingAddress={isDisplayingAddress}
        />
      </section>
    </main>
  );
};
