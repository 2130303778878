import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { globalDefaultContext } from '../../contexts/GlobalContext';

export const About = () => {
  const { t } = useTranslation();

  return (
    <Box
      py={globalDefaultContext.marginBetweenSections}
      bgColor={useColorModeValue('yellow.200', 'red.800')}>
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading>{t('about.title')}</Heading>
        <Text fontSize={'xl'}>{t('about.text')}</Text>
      </Stack>
    </Box>
  );
};
