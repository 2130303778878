import { ChangeEvent } from 'react';

export function initForm() {
  const forms = document.querySelectorAll('.needs-validation');

  Array.prototype.slice.call(forms).forEach(function (form) {
    form.addEventListener(
      'submit',
      function (event: ChangeEvent<HTMLFormElement>) {
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }

        form.classList.add('was-validated');
      },
      false
    );
  });
}

export function focusField(id: string) {
  const focusField = document.getElementById(id);

  if (focusField) {
    focusField.focus();
  }
}
