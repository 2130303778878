import { FormControl, FormLabel, Link, Switch } from '@chakra-ui/react';
import { ChangeEvent } from 'react';
import { Trans } from 'react-i18next';

interface SwitchTermsAndConditionsProps {
  isChecked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SwitchTermsAndConditions = ({
  isChecked,
  onChange,
}: SwitchTermsAndConditionsProps) => {
  return (
    <FormControl display="flex" alignItems="center">
      <Switch
        id="terms-and-conditions"
        isChecked={isChecked}
        mr="3"
        onChange={onChange}
        size="lg"
        required
        colorScheme={'green'}
      />
      <FormLabel htmlFor="terms-and-conditions" mb="0">
        <Trans i18nKey="form.termsAndConditionsText">
          I read and agree to&nsbp;
          <Link href="/page/terms" color={'blue.600'} isExternal>
            termsAndConditionsLink
          </Link>
          .
        </Trans>
      </FormLabel>
    </FormControl>
  );
};
