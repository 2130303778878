import { useContext } from 'react';
import { MemberDashboard } from '../components/MemberDashboard/MemberDashboard';
import { AuthContext } from '../services/auth';
import { useNavigate } from 'react-router-dom';
import { URL_ROOT } from '../utils/constant';

export const MemberArea: React.FC = () => {
  const { userData, isAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  if (!isAuthenticated) {
    navigate(URL_ROOT);
  }

  return (
    <main className="member-area">
      <section>
        <MemberDashboard userData={userData} />
      </section>
    </main>
  );
};
