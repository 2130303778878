import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  CloseButton,
} from '@chakra-ui/react';
import Markdown from 'markdown-to-jsx';
import { useContext } from 'react';
import GlobalContext from '../../contexts/GlobalContext';

interface AlertBoxProps {
  status: 'success' | 'info' | 'warning' | 'error' | 'loading' | undefined;
  title?: string;
  description: string;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const AlertBox = ({
  status = 'success',
  title,
  description,
  onClose,
}: AlertBoxProps) => {
  const { marginBetweenSections } = useContext(GlobalContext);

  return (
    <Alert
      alignItems="center"
      borderRadius="4"
      flexDirection="column"
      id={`alert-${status}`}
      justifyContent="center"
      mb={status === 'success' ? marginBetweenSections : 5}
      mt="5"
      p={status === 'success' ? 5 : 3}
      status={status}
      textAlign={status === 'success' ? 'center' : 'left'}
      variant="subtle">
      <CloseButton
        alignSelf="flex-start"
        position="absolute"
        right={2}
        top={2}
        onClick={onClose}
      />
      <AlertIcon boxSize="40px" mb={2} mr={0} />
      {title && (
        <AlertTitle mt={2} mb={1} fontSize="lg">
          {title}
        </AlertTitle>
      )}
      <AlertDescription maxWidth="sm">
        <Markdown>{description}</Markdown>
      </AlertDescription>
    </Alert>
  );
};
