import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import deDE from './de-DE.json';
import enUS from './en-US.json';
import esES from './es-ES.json';
import frFR from './fr-FR.json';
import itIT from './it-IT.json';

const resources = {
  'de-DE': {
    translation: deDE,
  },
  'en-US': {
    translation: enUS,
  },
  'es-ES': {
    translation: esES,
  },
  'fr-FR': {
    translation: frFR,
  },
  'it-IT': {
    translation: itIT,
  },
};

const options = {
  order: ['cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupSessionStorage: 'i18nextLng',
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'],
  cookieMinutes: 10,
  htmlTag: document.documentElement,
  // cookieOptions: { path: '/', sameSite: 'strict' },
  // fallbackLng: ['de-DE', 'en-US', 'es-ES', 'fr-FR', 'it-IT'],
  //   interpolation: {
  //     escapeValue: false,
  //   },
  //   react: {
  //     bindI18n: 'languageChanged editorSaved',
  //   },
  //   resources,
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    fallbackLng: ['de-DE', 'en-US', 'es-ES', 'fr-FR', 'it-IT'],
    interpolation: {
      escapeValue: false,
    },
    react: {
      bindI18n: 'languageChanged editorSaved',
    },
    resources,
  });

export default i18n;
