import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Text,
  Stack,
  useColorModeValue,
  Flex,
} from '@chakra-ui/react';
import { globalDefaultContext } from '../../contexts/GlobalContext';
import { useTranslation } from 'react-i18next';

export const Features = () => {
  const { t } = useTranslation();

  const features = [];

  for (let i = 1; i <= 5; i++) {
    features.push({
      id: i,
      title: t('features.title' + i),
      text: t('features.text' + i),
    });
  }

  return (
    <Box
      py={globalDefaultContext.marginBetweenSections}
      bgColor={useColorModeValue('brand.light', 'brand.dark')}>
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading>{t('features.title')}</Heading>
        <Text fontSize={'xl'}>{t('features.text')}</Text>
      </Stack>

      <Container maxW={'4xl'} mt={10}>
        {features.map((feature) => (
          <SimpleGrid
            key={feature.id}
            columns={{ base: 1, lg: 2 }}
            spacing={5}
            my={5}>
            <Flex alignItems={'center'}>
              <Heading as={'h3'} size={'md'}>
                {feature.title}
              </Heading>
            </Flex>
            <Box>{feature.text}</Box>
          </SimpleGrid>
        ))}
      </Container>
    </Box>
  );
};
