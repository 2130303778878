import { directusWithStaticToken } from '../services/directus';
import { DEFAULT_LANGUAGE } from './language';
import { getTranslation } from './translation';
import { Addresses, Countries } from './types';

const {
  REACT_APP_ITEMS_ADDRESSES,
  REACT_APP_ITEMS_COUNTRIES,
  REACT_APP_ITEMS_COUNTRIES_TRANSLATIONS,
  REACT_APP_ITEMS_STATES,
  REACT_APP_ITEMS_STATES_TRANSLATIONS,
} = process.env;

export const getCountry = async (country: number) => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_COUNTRIES}`)
    .readOne(country);
};

export const getState = async (state: number) => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_STATES}`)
    .readOne(state);
};

export const getAddress = async (addressId: number) => {
  return await directusWithStaticToken
    .items(`${REACT_APP_ITEMS_ADDRESSES}`)
    .readOne(addressId);
};

export const handleAddress = (
  addressData: Addresses,
  currentLanguage: string
) => {
  const handleCountry =
    addressData.country &&
    getCountry(Number(addressData.country)).then((response: any) => {
      if (response) {
        const defaultCountry = {
          languages_code: DEFAULT_LANGUAGE,
          name: response.name,
        };

        if (
          defaultCountry.languages_code === currentLanguage ||
          response.translations.length === 0
        ) {
          addressData.country = response.name;

          return addressData;
        }

        return getTranslation(
          `${REACT_APP_ITEMS_COUNTRIES_TRANSLATIONS}`,
          response.translations,
          ['languages_code', 'name']
        ).then((response: any) => {
          const currentTranslation = response.data.filter(
            (translation: any) => {
              return String(translation.languages_code) === currentLanguage;
            }
          );

          addressData.country = currentTranslation[0].name;

          return addressData;
        });
      }
    });

  const handleState =
    addressData.state &&
    getState(Number(addressData.state)).then((response: any) => {
      if (response) {
        const defaultState = {
          languages_code: DEFAULT_LANGUAGE,
          name: response.name,
        };

        if (
          defaultState.languages_code === currentLanguage ||
          response.translations.length === 0
        ) {
          addressData.state = response.name;

          return addressData;
        }

        return getTranslation(
          `${REACT_APP_ITEMS_STATES_TRANSLATIONS}`,
          response.translations,
          ['languages_code', 'name']
        ).then((response: any) => {
          if (response.data.length > 0) {
            const currentTranslation = response.data.filter(
              (translation: any) => {
                return String(translation.languages_code) === currentLanguage;
              }
            );

            addressData.state = currentTranslation[0].name;

            return addressData;
          }
        });
      }
    });

  return Promise.all([handleCountry, handleState]).then((values) => {
    return values;
  });
};
