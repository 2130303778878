import { Container, Heading, Link } from '@chakra-ui/react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../services/auth';
import { URL_ROOT } from '../utils/constant';

export const Dashboard: React.FC = () => {
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  if (!isAuthenticated) {
    navigate(URL_ROOT);
  }

  return (
    <main className="dashboard">
      <section>
        <Container>
          <Heading>Dashboard</Heading>
          <p>
            <Link onClick={logout}>Logout</Link>
          </p>
        </Container>
      </section>
    </main>
  );
};
