import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchInput } from '../SearchInput/SearchInput';

export const SearchWidget = () => {
  const [input, setInput] = useState('');
  const navigate = useNavigate();

  return (
    <SearchInput
      value={input}
      onInput={(event) => setInput(event.target.value)}
      onSubmit={() => navigate('/search?q=' + input)}
    />
  );
};
