import { directusWithStaticToken } from '../services/directus';

export async function getTranslation(
  items: string,
  translations: number[],
  fields: string[]
) {
  const translationResponse = await directusWithStaticToken
    .items(items)
    .readMany(translations, {
      fields: fields,
    });

  return translationResponse;
}
